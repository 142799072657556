<template>
<div >
    <div v-if="user.avatar">
        <img v-bind:src="'https://apitraining.vipawaworks.com/' + user.avatar" class="rounded-circle mr-2 img-150" alt="">
    </div>
    <div v-else>
        <img src="@/assets/img/team/1.jpg" class="rounded-circle mr-2 img-150" alt="">
    </div>
    
    <h6>{{ user.fname }} {{ user.sname }} {{ user.lname }} </h6>
</div>
    <ul class="nav flex-column nav-pills nav-fill">
        <li class="nav-item">
            <router-link :to="{ name: 'TrainerDashboard' }" class="nav-link btn">Dashboard</router-link>
        </li>
        <li class="nav-item" v-if="user.status != 1">
            <router-link :to="{ name: 'TrainerCourses' }" class="nav-link btn">Manage Courses</router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{ name: 'TrainerClassRoom' }" class="nav-link btn">Class Rooms</router-link>
        </li>
        <li class="nav-item" v-if="user.status == 1">
            <router-link :to="{ name: 'TrainerCV' }" class="nav-link btn">Curriculum Vitae</router-link>
        </li>
        <li class="nav-item">
            <router-link :to="{ name: 'TrainerProfile' }" class="nav-link btn">Profile</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    props:['user'],

}
</script>

<style scoped>
.img-150{
  height: 150px;
}
</style>